import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 839.000000 618.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,618.000000) scale(0.100000,-0.100000)">
<path d="M3905 5524 c-996 -90 -1763 -400 -2425 -980 -58 -50 -116 -103 -129
-118 l-24 -25 251 -258 c138 -142 300 -307 361 -368 l110 -109 23 20 c240 216
457 364 733 499 378 185 688 266 1188 311 l67 6 0 519 c0 285 -1 518 -2 518
-2 -1 -70 -7 -153 -15z"/>
<path d="M4240 5019 l0 -517 68 -6 c504 -45 840 -135 1217 -325 244 -124 416
-241 681 -466 l46 -39 359 364 c197 201 358 368 359 372 0 11 -227 211 -342
300 -615 479 -1389 759 -2285 826 l-103 8 0 -517z"/>
<path d="M3910 4265 c-597 -67 -1106 -267 -1480 -583 -52 -44 -118 -104 -147
-134 l-51 -53 337 -344 338 -344 83 72 c276 238 581 366 953 400 60 5 110 11
112 13 2 2 7 225 11 496 l7 492 -24 -1 c-13 -1 -76 -7 -139 -14z"/>
<path d="M4233 3789 c4 -271 9 -494 11 -496 2 -3 53 -9 113 -14 372 -34 677
-162 953 -400 l83 -72 309 314 c169 173 320 329 335 348 l28 34 -95 89 c-255
241 -548 411 -915 531 -232 77 -460 122 -764 152 l-64 6 6 -492z"/>
<path d="M1840 3443 c-462 -488 -722 -1112 -747 -1790 l-6 -173 369 0 370 0
11 178 c14 212 40 352 98 529 93 283 253 548 461 763 l53 55 -257 263 c-141
145 -260 263 -263 262 -4 0 -44 -39 -89 -87z"/>
<path d="M6106 3271 l-260 -260 63 -67 c352 -376 543 -836 553 -1332 l3 -132
374 0 374 0 -6 163 c-24 610 -226 1162 -594 1622 -82 103 -230 265 -242 265
-3 0 -123 -117 -265 -259z"/>
<path d="M3959 3045 c-330 -45 -630 -174 -852 -367 l-38 -33 205 -204 204
-204 59 50 c142 121 315 204 476 227 l47 7 0 270 c0 148 -1 269 -2 268 -2 -1
-46 -7 -99 -14z"/>
<path d="M4234 2998 c3 -33 6 -154 6 -269 l0 -207 43 -7 c154 -24 330 -105
465 -216 l75 -61 204 204 205 205 -83 65 c-253 197 -495 291 -883 342 l-39 5
7 -61z"/>
<path d="M2511 2773 c-299 -322 -480 -779 -469 -1184 l3 -104 330 -3 c182 -1
345 0 363 3 31 6 32 8 26 40 -9 51 14 235 42 333 47 161 136 317 247 431 l56
57 -258 258 -258 258 -82 -89z"/>
<path d="M5447 2602 l-257 -258 41 -39 c96 -93 202 -263 247 -400 34 -100 53
-211 54 -316 1 -92 2 -100 22 -104 12 -3 174 -4 361 -3 l340 3 -2 150 c-2 245
-57 470 -173 710 -84 174 -212 356 -332 472 l-43 43 -258 -258z"/>
<path d="M3184 2158 c-142 -157 -217 -339 -231 -556 -5 -84 -3 -106 8 -113 16
-10 149 -12 173 -3 13 5 16 23 16 88 0 162 64 326 172 441 l57 62 -67 66 c-37
37 -70 67 -74 67 -3 0 -28 -24 -54 -52z"/>
<path d="M4987 2142 l-67 -68 31 -29 c118 -112 187 -266 196 -445 3 -59 11
-108 17 -112 19 -13 161 -9 174 4 24 24 7 205 -30 327 -22 73 -75 184 -118
246 -33 48 -119 145 -129 145 -3 0 -37 -31 -74 -68z"/>
<path d="M5756 1370 c-61 -19 -106 -85 -106 -155 0 -45 10 -65 71 -138 52 -62
59 -86 33 -111 -21 -21 -52 -20 -99 4 -22 11 -40 20 -41 20 -7 0 -23 -134 -17
-140 17 -15 78 -30 123 -30 63 0 108 16 147 53 41 38 57 85 51 148 -5 48 -12
59 -90 152 -26 30 -30 41 -22 55 15 23 66 27 102 8 l29 -15 9 64 c10 62 9 64
-16 75 -33 16 -137 22 -174 10z"/>
<path d="M1485 1348 c-3 -13 -21 -126 -40 -253 -20 -126 -38 -238 -41 -247 -5
-17 6 -18 129 -18 l135 0 11 63 c6 35 11 67 11 70 0 4 -25 7 -55 7 l-55 0 0
35 0 35 49 0 c27 0 53 6 58 13 6 6 13 36 17 65 l7 52 -51 0 -51 0 3 33 c3 32
4 32 63 37 l60 5 7 40 c16 91 25 85 -122 85 -126 0 -130 -1 -135 -22z"/>
<path d="M1940 1364 c0 -3 -27 -123 -60 -266 -33 -143 -60 -261 -60 -264 0 -2
29 -4 63 -4 l64 0 26 138 c14 75 29 156 33 180 14 90 24 34 24 -139 l0 -180
52 3 51 3 63 190 c35 105 61 177 59 160 -2 -16 -11 -93 -20 -170 -8 -77 -18
-150 -20 -162 -5 -22 -3 -23 59 -23 l64 0 6 48 c3 26 13 133 21 237 9 105 18
205 21 223 l5 32 -89 0 -90 0 -45 -137 -45 -138 -1 138 -1 137 -90 0 c-49 0
-90 -3 -90 -6z"/>
<path d="M2601 1333 c-18 -69 -111 -482 -111 -493 0 -7 23 -10 62 -8 l62 3 37
185 c32 164 37 178 38 125 1 -33 4 -117 8 -187 l6 -128 48 0 48 0 58 182 c32
100 59 175 61 167 2 -9 -6 -88 -18 -175 -11 -87 -20 -162 -20 -166 0 -5 29 -8
65 -8 55 0 65 3 65 18 0 9 9 118 20 242 11 123 20 237 20 253 l0 28 -86 -3
-86 -3 -43 -130 -43 -130 -1 71 c-1 39 -4 99 -7 133 l-7 61 -83 0 -83 0 -10
-37z"/>
<path d="M3245 1163 c-43 -115 -89 -236 -102 -271 l-23 -63 77 3 76 3 12 35
c11 33 15 35 58 38 l47 3 0 -40 0 -41 75 0 76 0 -6 58 c-5 48 -35 460 -35 477
0 3 -40 5 -89 5 l-88 0 -78 -207z m145 -53 l0 -90 -31 0 c-24 0 -30 4 -25 16
3 8 16 49 27 90 12 41 24 74 26 74 1 0 3 -40 3 -90z"/>
<path d="M3755 1338 c-3 -18 -23 -140 -44 -270 l-37 -238 62 0 c73 0 66 -12
90 148 10 61 22 112 26 112 4 0 8 -7 8 -17 0 -9 10 -67 21 -130 l21 -113 69 0
c38 0 69 2 69 4 0 2 18 117 40 255 22 138 40 258 40 266 0 12 -13 15 -59 15
-69 0 -62 11 -87 -140 -24 -148 -28 -155 -42 -63 -7 43 -17 106 -23 141 l-11
62 -69 0 -68 0 -6 -32z"/>
<path d="M4300 1362 c0 -4 -14 -97 -30 -205 -32 -205 -31 -244 4 -289 20 -27
80 -48 134 -48 108 0 171 46 196 144 15 57 66 360 66 391 0 12 -16 15 -73 15
l-73 0 -28 -182 c-28 -180 -43 -228 -71 -228 -31 0 -32 35 -4 205 15 94 28
178 28 188 1 15 -9 17 -74 17 -41 0 -75 -4 -75 -8z"/>
<path d="M4840 1358 c0 -7 -18 -125 -40 -261 -22 -137 -40 -253 -40 -258 0
-11 257 -12 264 -1 2 4 8 36 12 70 l7 62 -57 0 c-58 0 -62 3 -50 47 5 19 14
23 58 25 l51 3 11 63 11 62 -55 0 c-51 0 -54 1 -48 23 3 12 6 28 6 35 0 8 19
12 59 12 l59 0 6 38 c4 20 9 49 12 65 l6 27 -136 0 c-102 0 -136 -3 -136 -12z"/>
<path d="M5270 1358 c0 -7 -18 -125 -40 -261 -22 -137 -40 -253 -40 -258 0 -5
56 -9 129 -9 l129 0 6 38 c4 20 9 49 12 65 6 27 5 27 -50 27 -31 0 -56 3 -56
8 0 4 14 90 30 192 16 102 30 191 30 198 0 8 -22 12 -75 12 -53 0 -75 -4 -75
-12z"/>
<path d="M6097 1193 c-35 -229 -35 -282 -2 -322 34 -40 84 -55 161 -48 79 6
126 34 153 90 18 37 81 378 81 438 0 18 -5 20 -72 17 l-73 -3 -31 -193 c-30
-193 -38 -216 -74 -209 -24 4 -23 50 5 217 14 85 25 162 25 172 0 16 -10 18
-73 18 l-73 0 -27 -177z"/>
<path d="M6665 1358 c-2 -7 -20 -116 -40 -243 -20 -126 -38 -242 -41 -257 l-6
-28 109 0 c172 1 218 19 261 101 33 62 25 114 -22 155 l-36 31 30 13 c49 20
70 55 70 117 0 94 -52 123 -223 123 -69 0 -99 -4 -102 -12z m166 -108 c38 -21
14 -90 -33 -90 -18 0 -19 4 -13 43 11 62 13 64 46 47z m-23 -202 c29 -29 1
-108 -39 -108 -21 0 -23 17 -13 83 7 39 28 49 52 25z"/>
<path d="M3030 688 c-5 -13 -21 -84 -35 -158 -15 -74 -29 -145 -32 -158 -5
-21 -3 -23 28 -20 l33 3 33 160 c17 88 32 168 33 178 0 27 -48 23 -60 -5z"/>
<path d="M4432 698 c-5 -7 -13 -34 -17 -61 -7 -48 -18 -66 -30 -47 -10 16 -78
12 -104 -6 -56 -39 -82 -182 -41 -219 24 -22 73 -22 106 0 22 14 24 14 24 0 0
-12 8 -15 27 -13 27 3 28 6 60 168 18 91 31 171 28 178 -2 6 -13 12 -23 12
-11 0 -24 -6 -30 -12z m-57 -162 c17 -12 18 -18 7 -55 -21 -68 -82 -107 -97
-61 -13 40 28 130 60 130 6 0 20 -6 30 -14z"/>
<path d="M3163 694 c-19 -8 -16 -54 3 -61 24 -9 49 10 49 38 0 24 -24 34 -52
23z"/>
<path d="M3712 688 c-15 -15 -16 -45 -1 -53 30 -19 73 18 62 53 -6 15 -45 16
-61 0z"/>
<path d="M2408 674 c-3 -3 -16 -58 -29 -123 -19 -103 -21 -123 -9 -150 17 -41
42 -54 106 -54 62 0 110 25 136 69 10 17 29 83 42 147 l23 117 -33 0 c-19 0
-34 -2 -34 -4 0 -20 -45 -216 -53 -231 -23 -45 -81 -60 -111 -29 -20 20 -20
32 4 149 11 53 20 101 20 106 0 10 -53 13 -62 3z"/>
<path d="M4675 668 c-18 -57 -62 -305 -55 -312 5 -5 47 -7 94 -4 70 4 91 9
124 31 52 34 82 96 82 171 0 99 -36 126 -167 126 -50 0 -75 -4 -78 -12z m155
-58 c39 -39 18 -151 -34 -185 -28 -19 -106 -32 -106 -19 0 5 10 57 21 117 l22
107 38 0 c26 0 46 -7 59 -20z"/>
<path d="M3847 654 c-4 -4 -7 -16 -7 -26 0 -11 -11 -23 -25 -28 -29 -11 -37
-60 -9 -60 12 0 15 -6 10 -22 -3 -13 -9 -49 -12 -80 -8 -71 3 -88 58 -88 31 0
40 4 47 23 7 18 5 23 -13 25 -34 6 -36 14 -24 79 11 59 13 62 42 65 25 2 32 8
34 31 3 23 0 27 -23 27 -23 0 -26 4 -23 28 2 23 -1 27 -22 30 -14 2 -29 0 -33
-4z"/>
<path d="M5285 630 c-4 -16 -13 -30 -20 -30 -17 0 -38 -36 -30 -50 3 -6 12
-10 19 -10 11 0 11 -13 -1 -71 -20 -97 -10 -119 51 -119 31 0 46 5 50 16 10
27 7 34 -19 34 -29 0 -31 12 -14 90 9 42 14 50 34 50 27 0 49 24 41 45 -3 8
-16 15 -29 15 -20 0 -23 4 -19 30 4 28 2 30 -26 30 -26 0 -31 -5 -37 -30z"/>
<path d="M2730 596 c0 -5 -38 -194 -45 -224 -5 -21 -3 -23 27 -20 31 3 33 6
48 68 18 76 33 105 61 121 37 20 44 -2 30 -88 -7 -42 -15 -83 -18 -90 -4 -11
2 -14 28 -11 l34 3 19 96 c19 92 19 98 3 123 -22 33 -73 35 -111 5 l-26 -20 0
20 c0 16 -6 21 -25 21 -14 0 -25 -2 -25 -4z"/>
<path d="M3135 578 c-8 -33 -45 -219 -45 -225 0 -2 14 -3 32 -1 l32 3 23 110
c12 61 20 116 18 123 -2 6 -16 12 -29 12 -18 0 -27 -7 -31 -22z"/>
<path d="M3265 588 c-5 -17 -45 -218 -45 -229 0 -14 60 -10 65 4 2 6 9 39 16
71 8 43 20 68 41 89 26 26 31 28 44 15 13 -12 13 -23 -1 -87 -20 -98 -20 -101
14 -101 32 0 33 1 51 92 9 44 18 63 43 84 28 23 32 24 43 10 9 -13 8 -31 -2
-79 -21 -96 -19 -107 15 -107 27 0 30 4 41 53 22 100 24 161 5 180 -22 23 -77
22 -107 -2 -24 -20 -25 -20 -41 0 -20 23 -57 24 -97 4 -27 -14 -30 -14 -30 0
0 18 -49 21 -55 3z"/>
<path d="M3694 588 c-11 -17 -52 -227 -46 -233 9 -8 50 -6 56 4 8 12 46 198
46 222 0 22 -44 27 -56 7z"/>
<path d="M4019 573 c-39 -33 -59 -79 -59 -133 0 -56 14 -77 56 -89 37 -10 101
-4 122 12 19 15 15 49 -5 43 -76 -22 -113 -13 -113 28 0 22 5 24 60 28 67 5
110 34 110 75 0 65 -109 88 -171 36z m100 -27 c17 -21 -13 -46 -55 -46 -31 0
-34 2 -24 20 20 37 59 50 79 26z"/>
<path d="M5011 584 c-56 -39 -82 -182 -41 -219 24 -22 73 -22 106 0 22 14 24
14 24 0 0 -12 8 -15 27 -13 26 3 28 7 50 113 12 61 22 116 23 123 0 18 -47 15
-58 -4 -8 -14 -10 -14 -21 0 -18 21 -80 21 -110 0z m94 -48 c17 -12 18 -18 7
-55 -21 -68 -82 -107 -97 -61 -13 40 28 130 60 130 6 0 20 -6 30 -14z"/>
<path d="M5455 567 c-67 -67 -73 -193 -10 -217 24 -9 48 -3 108 28 4 2 4 -3 1
-12 -5 -12 1 -16 24 -16 l29 0 22 108 c11 59 21 115 21 125 0 21 -38 23 -46 2
-5 -13 -8 -13 -27 0 -37 26 -85 19 -122 -18z m108 -34 c13 -16 14 -23 2 -56
-17 -50 -44 -79 -69 -75 -52 7 -16 148 37 148 8 0 21 -8 30 -17z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
